import React, { useEffect, useState } from "react";
import { collection, query, onSnapshot, where, getDocs } from "firebase/firestore";
import { db } from "../../../../Firebase Files/firebaseConfig";
import { Icon } from "../../../../components/Component";
import { getCookie } from "../../../../utils/Utils";

const ChatNotification = () => {
  const [unreadChatIds, setUnreadChatIds] = useState([]);
  const currentUserId = parseInt(getCookie("userId"));

  useEffect(() => {
    // Set up a listener to get unread messages and last message in real-time
    async function setupChatListeners() {
      try {
        // Query to find chat rooms for the current user
        const chatRoomsQuery = query(collection(db, "Chats"), where("participants", "array-contains", currentUserId));

        const chatRoomsSnapshot = await getDocs(chatRoomsQuery);
        const chatIdsWithUnreadMessages = new Set();

        chatRoomsSnapshot.forEach((doc) => {
          const chatRoomId = doc.id;

          // Query for unread messages in the chatroom (read: true and not sent by the current user)
          const unreadMessagesQuery = query(collection(db, "Chats", chatRoomId, "messages"), where("read", "==", true));

          const unsubscribeUnreadMessages = onSnapshot(unreadMessagesQuery, (snapshot) => {
            const filteredUnreadMessages = snapshot.docs.filter((doc) => doc.data().senderId !== currentUserId);
            // console.log(filteredUnreadMessages);
            if (filteredUnreadMessages.length > 0) {
              // If there are unread messages not sent by the current user, add chatRoomId to the set
              chatIdsWithUnreadMessages.add(chatRoomId);
            } else {
              // Remove chatRoomId if no such unread messages are left
              chatIdsWithUnreadMessages.delete(chatRoomId);
            }

            setUnreadChatIds(chatIdsWithUnreadMessages.size);

            // Optional: Clean up the listener for this chatRoomId on unmount
            return () => {
              unsubscribeUnreadMessages();
            };
          });
        });
      } catch (error) {
        console.error("Error setting up listeners for chats:", error);
      }
    }

    setupChatListeners();
  }, [currentUserId]);
  console.log("unreadChatIds :", unreadChatIds.Set);

  return (
    <div className="position-relative align-center">
      <Icon name="chat" className="size-md" style={{ fontSize: "1.5rem", cursor: "pointer" }} />
      {unreadChatIds > 0 && (
        <span
          className="position-absolute bg-danger border border-light rounded-circle d-flex align-items-center justify-content-center"
          style={{
            top: "-0.55rem",
            right: "-0.55rem",
            width: "1.5rem", // Adjust width and height for badge size
            height: "1.5rem",
            fontSize: "0.65rem", // Adjust font size for badge content
            color: "white",
            padding: "10px",
          }}
        >
          {unreadChatIds}
        </span>
      )}
    </div>
  );
};

export default ChatNotification;
