import React, { useEffect, useState, useContext, useRef } from "react";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import SimpleBar from "simplebar-react";
import { UserAvatar, Icon, Button } from "../../components/Component";
import { CommonToaster, currentTime, findUpper, getInitiatals, truncate } from "../../utils/Utils";
import { ChatContext } from "./ChatContext";
import classNames from "classnames";
import EmojiPicker from "emoji-picker-react"; // Import the emoji picker

import ChatSideBar from "./ChatSideBar";
import { MeChat, YouChat, MetaChat } from "./ChatPartials";
import { updateChattingIds, updateUserConvo, updateUserStatus } from "../../Firebase Files/firebase-service";
import {
  addDoc,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { db, storage } from "../../Firebase Files/firebaseConfig";
import ShareMessageModal from "../components/ShareMessageModal";
import { useTheme } from "../../layout/provider/Theme";

const ChatBody = ({
  id,
  name,
  mobileView,
  setMobileView,
  setSelectedId,
  username,
  currentUser,
  selectedUser,
  createNewChat,
  setTextMsg,
  textMsg,
  user,
  setCurrentUser,
  chatItemClick,
  sharedPhotos,
}) => {
  const { deleteConvo, propAction, chatState } = useContext(ChatContext);
  // const [chatState, setChatState] = useState([
  //   [
  //     {
  //       id: 14231,
  //       chattingIds: [14231, 13842, 13987, 3],
  //       visible: true,
  //       createdAt: "2024-11-09T07:26:40.304Z",
  //       profilePic:
  //         "https://api.silocloud.io/get-file-content/users/private/14231/UserProfile/14231.?expires=1731140798&signature=17c2730ff685d6e541c87aa6c19417caeb24948385bd953860dddd2847716684",
  //       name: "Do_er",
  //       active: true,
  //     },
  //     {
  //       id: 5,
  //       chattingIds: [5, 4],
  //       name: "Do_er",
  //       visible: true,
  //       profilePic: null,
  //       createdAt: "2024-11-11T05:18:14.014Z",
  //       active: true,
  //     },
  //     {
  //       id: 3,
  //       name: "PBelvalkar",
  //       active: true,
  //       visible: true,
  //       chattingIds: [3, 4],
  //       profilePic:
  //         "https://api.silocloud.io/get-file-content/users/private/13987/UserProfile/13987.?expires=1730976401&signature=aebf6c2ac83078580956924d270ff1759f275bc6a1ba8e006c678df5b2a6761d",
  //       createdAt: "2024-11-12T04:39:38.605Z",
  //     },
  //     {
  //       id: 13987,
  //       name: "TestUser19",
  //       profilePic:
  //         "https://api.silocloud.io/get-file-content/users/private/13987/UserProfile/13987.?expires=1729854717&signature=b70994ad69f185d3cac3b6c38018542572dfc0796a69a01f6f3c887ae9a69bcd",
  //       active: true,
  //       createdAt: "2024-11-16T09:58:24.839Z",
  //     },
  //     {
  //       id: 11529,
  //       profilePic: "",
  //       chattingIds: [13842],
  //       visible: true,
  //       name: "anujap",
  //       messages: false,
  //       createdAt: "2024-11-12T12:23:13.405Z",
  //     },
  //     {
  //       id: 13842,
  //       visible: true,
  //       createdAt: "2024-11-09T07:20:16.010Z",
  //       profilePic:
  //         "https://api.silocloud.io/get-file-content/users/private/13842/UserProfile/13842.?expires=1731140414&signature=6b79f5f79ab6ebde9ba0b6e699abe90bb854c123ca14726f0a4b9a7d8f59df49",
  //       chattingIds: [13842, 14231, 3, 11529],
  //       name: "babafe",
  //       active: true,
  //     },
  //     {
  //       id: 4,
  //       name: "babafe",
  //       chattingIds: [4, 5, 3],
  //       profilePic:
  //         "https://api.silocreditunion.com/get-file-content/users/private/13842/UserProfile/13842.?expires=1731305816&signature=c9b0a5382973a904b16943475a1e61ee57ed1b5f70a71e8b93a80f9308a2a217",
  //       active: true,
  //       visible: true,
  //       createdAt: "2024-11-11T05:16:56.758Z",
  //     },
  //     {
  //       id: 13754,
  //       profilePic:
  //         "https://api.silocloud.io/get-file-content/users/private/13754/UserProfile/13754.?expires=1731479620&signature=cb20dd5c3279fdb7d42b8a4a0b78d0abcd290b83ccdc3326807dd6b778f8cc88",
  //       active: true,
  //       createdAt: "2024-11-13T05:33:40.908Z",
  //       name: "sachita9187",
  //       chattingIds: [13754, 11286],
  //       visible: true,
  //     },
  //     {
  //       id: 11286,
  //       name: "sachita",
  //       visible: true,
  //       profilePic:
  //         "https://api.silocloud.io/get-file-content/users/private/11286/UserProfile/11286.?expires=1731393152&signature=8ec0a6cd139116f809372af60cb356c590b5fb0b0cd1e9ec1f5ff29ef8f2037b",
  //       chattingIds: [13842, 13754],
  //       createdAt: "2024-11-12T05:32:33.291Z",
  //       active: true,
  //     },
  //     {
  //       id: 6,
  //       chattingIds: [6],
  //       active: true,
  //       profilePic: "https://api.silocloud.io/get-file-content/users/private/6/UserProfile/userImage.jpeg",
  //       createdAt: "2024-11-11T09:18:16.539Z",
  //       name: "Unknown User",
  //       visible: true,
  //     },
  //   ],
  // ]);
  // const { deleteConvo, propAction } = useContext(ChatContext);
  const [chat, setChat] = chatState;
  const [messages, setMessages] = useState([]);
  const theme = useTheme();
  // console.log("messages", messages);
  const [showToast, setShowToast] = useState(false);
  const [showToastTheme, setShowToastTheme] = useState("");

  const [showToastMessage, setShowToastMessage] = useState("");
  const [uploadingFiles, setUploadingFiles] = useState({}); // Track each file's upload progress
  const [uploading, setUploading] = useState(false); // Overall upload status

  const toggleToast = () => {
    setShowToast(!showToast); // Toggle the showToast state
    setShowToastMessage(""); // Clear the toast message after hiding
    setShowToastTheme(""); // Clear the toast message after hiding
  };

  // console.log("selectedUser", selectedUser);

  useEffect(() => {
    if (selectedUser?.chatId) {
      const messagesRef = collection(db, "Chats", selectedUser.chatId, "messages");
      const q = query(messagesRef, orderBy("timestamp", "asc"));

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const updatedMessages = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        // console.log(updatedMessages);
        setMessages(updatedMessages);
      });

      return () => unsubscribe();
    }
  }, [selectedUser?.chatId]);

  const [Uchat, setUchat] = useState({});
  const [sidebar, setsidebar] = useState(false);
  const [inputText, setInputText] = useState("");
  const [chatOptions, setChatOptions] = useState(false);

  const emojiPickerRef = useRef(null);
  const messageRefs = useRef({});
  const textareaRef = useRef(null);

  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [editingMessageId, setEditingMessageId] = useState(null);
  const [replyingToMessageId, setReplyingToMessageId] = useState(null);
  const [incomingID, setIncomingID] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [forwardMessages, setForwardMessages] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]); // State to hold selected files
  const [singleMessage, setSingleMessage] = useState("");
  const [shareableContact, setShareableContact] = useState([]);
  const [shareFiles, setShareFiles] = useState([]);

  // console.log("shareableContact", shareableContact);

  // useEffect(() => {
  //   if (selectedUser?.chatId) {
  // const messagesRef = collection(db, "Chats", selectedUser.chatId, "messages");
  // const q = query(messagesRef, orderBy("timestamp", "asc"));

  //     const unsubscribe = onSnapshot(q, (snapshot) => {
  //       const unreadMessages = snapshot.docs
  //         .filter((doc) => doc.data().read === true) // Filter only read messages
  //         .map((doc) => ({
  //           id: doc.id,
  //           ...doc.data(),
  //         }));

  //       // Update each message's `read` status to `false`
  //       unreadMessages.forEach(async (message) => {
  //         const messageRef = doc(db, "Chats", selectedUser.chatId, "messages", message.id);
  //         try {
  //           await updateDoc(messageRef, { read: false });
  //         } catch (error) {
  //           console.error("Error updating message read status:", error);
  //         }
  //       });
  //     });

  //     return () => unsubscribe();
  //   }
  // }, [Uchat]);

  // Function to toggle the modal
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);

    // When closing the modal, reset data
    if (isModalOpen) {
      setForwardMessages([]);
      setSingleMessage("");
      handleCancelSelect();
      setShareableContact([]);
      setShareFiles([]);
    }
    if (selectedMessages) {
      // Format the selected messages' text and time into a single string
      const messagesToCopy = selectedMessages.map((message) => ` ${message.text}  ${message.time}`).join("\n"); // Join each message with a new line

      // Copy the formatted string to the clipboard
      navigator.clipboard
        .writeText(messagesToCopy)
        .then(() => {
          setForwardMessages(messagesToCopy);
          console.log("Messages copied to clipboard!");
        })
        .catch((err) => {
          console.error("Error copying text: ", err);
        });
    }
  };

  useEffect(() => {
    if (isModalOpen === false) {
      setForwardMessages([]);
      setSingleMessage("");
      handleCancelSelect();
    }
  }, [isModalOpen]);

  useEffect(() => {
    // setInputText(textMsg);
    handleSendMessage1(textMsg);
  }, [textMsg]);

  const handleClickOutside = (event) => {
    if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target)) {
      setShowEmojiPicker(false);
    }
  };

  const toggleEmojiPicker = () => {
    setShowEmojiPicker((prev) => !prev);
  };

  const handleEmojiClick = (event, emojiObject) => {
    // If emojiObject is undefined, try accessing the emoji through the event
    const emoji = emojiObject?.emoji || event.emoji || event.native;

    if (emoji) {
      setInputText((prevComment) => prevComment + emoji);
    } else {
      console.error("Emoji not found in the event object", event);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      const scrollHeight = messagesEndRef.current.scrollHeight;
      const height = messagesEndRef.current.clientHeight;
      const maxScrollTop = scrollHeight - height;
      messagesEndRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages, uploadingFiles]);

  const resizeFunc = () => {
    if (window.innerWidth > 1550) {
      setsidebar(true);
    } else {
      setsidebar(false);
    }
  };

  useEffect(() => {
    chat.forEach((item) => {
      if (item.id === id) {
        setUchat(item);
      }
    });
  }, [id, chat]);

  useEffect(() => {
    window.addEventListener("resize", resizeFunc);
    resizeFunc();
    return () => {
      window.removeEventListener("resize", resizeFunc);
    };
  }, []);

  const onChatOptions = () => {
    setChatOptions(!chatOptions);
  };

  const onInputChange = (e) => {
    setInputText(e.target.value);
  };

  const toggleMenu = () => {
    setsidebar(!sidebar);
  };

  const handleFileUpload = async (files) => {
    setUploading(true); // Start the upload indicator

    const filePromises = Array.from(files).map((file) => {
      const storageRef = ref(storage, `chatFiles/${currentUser.id}/${Date.now()}_${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      return new Promise((resolve, reject) => {
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // Track upload progress for each file
            setUploadingFiles((prev) => ({
              ...prev,
              [file.name]: (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
            }));
          },
          (error) => {
            console.error("File upload failed:", error);
            setUploadingFiles((prev) => {
              const updatedFiles = { ...prev };
              delete updatedFiles[file.name];
              return updatedFiles;
            });
            reject(error);
          },
          async () => {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            setUploadingFiles((prev) => {
              const updatedFiles = { ...prev };
              delete updatedFiles[file.name];
              return updatedFiles;
            });
            resolve(downloadURL);
          }
        );
      });
    });

    try {
      const fileURLs = await Promise.all(filePromises);
      setUploading(false); // Stop the upload indicator after all files are done
      return fileURLs;
    } catch (error) {
      console.error("Error uploading files:", error);
      setUploading(false);
    }
  };

  const handleSendMessage1 = async (message, files) => {
    updateChattingIds(`${name}_${id}`, currentUser?.id);
    updateChattingIds(`${currentUser?.name || currentUser?.username}_${currentUser?.id}`, id);

    if ((message.trim() || files?.length > 0) && selectedUser?.chatId && currentUser) {
      const messagesRef = collection(db, "Chats", selectedUser.chatId, "messages");
      let fileURLs = [];
      console.log(selectedUser.chatId);
      // Handle file uploads if any files are selected
      if (files?.length > 0) {
        fileURLs = await handleFileUpload(files);
      }
      try {
        await addDoc(messagesRef, {
          text: message || "",
          senderId: currentUser.id,
          senderName: currentUser.name,
          fileURLs, // Store the uploaded file URLs in Firestore
          timestamp: new Date(),
          read: true,
        });
        const q = query(messagesRef, where("senderId", "!=", currentUser.id));
        // Retrieve the messages
        const unreadMessagesSnapshot = await getDocs(q);
        const unreadMessages = unreadMessagesSnapshot.docs;
        // Update the `read` status of each message to false
        unreadMessages.forEach(async (doc) => {
          const messageRef = doc.ref; // Get reference to the message
          console.log(messageRef);
          try {
            await updateDoc(messageRef, { read: false });
          } catch (error) {
            console.error("Error updating message read status:", error);
          }
        });
        setTextMsg(""); // Reset text input
        setChatOptions(false);
      } catch (error) {
        console.error("Error sending message:", error);
      }
    }
  };

  const handleCancelUpload = (fileName) => {
    // Logic to cancel the file upload
    console.log(`Canceling upload for: ${fileName}`);

    // Remove the file from uploadingFiles state or cancel the upload request here
    // For example, using a state management library or directly manipulating the state.
    setUploadingFiles((prev) => {
      const { [fileName]: _, ...rest } = prev; // Remove the file
      return rest;
    });
  };

  // Handle sending a new message as reply
  const handleSendMessage = async (replyingMsg, message, replyingToUserId, replyingToUsername) => {
    if (message?.trim() && user?.chatId && currentUser) {
      const messagesRef = collection(db, "Chats", user.chatId, "messages");
      try {
        await addDoc(messagesRef, {
          replyingMsg: replyingMsg,
          replyingToUserId: replyingToUserId || null, // Store replying message ID if applicable
          replyingToMessageId: replyingToMessageId,
          replyingToUsername: replyingToUsername,
          text: message,
          senderId: currentUser.id,
          timestamp: new Date(),
          reply: replyingToMessageId ? true : false,
          edited: false,
          senderName: currentUser?.name,
        });
        setInputText("");
      } catch (error) {
        console.error("Error sending message:", error);
      }
    }
  };

  // Handle file selection, allowing multiple files to be added to the list
  const handleFileChange = (e) => {
    const files = Array.from(e.target.files); // Convert FileList to an array
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]); // Merge with existing files
  };

  // Function to remove a selected file if needed
  const handleRemoveFile = (indexToRemove) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((_, index) => index !== indexToRemove));
  };
  const onTextSubmit = async (e) => {
    e.preventDefault();
    createNewChat(id);
    setChatOptions(false);

    // Ensure chat and Uchat are defined
    if (!chat || !Uchat) {
      console.error("Chat or Uchat is not defined");
      return;
    }

    // Ensure inputText is not empty or files are selected
    const files = document.getElementById("fileInput").files;
    if (inputText.trim() === "" && files.length === 0) return;

    let allChat = chat;
    let index = allChat.findIndex((item) => item.id === id);

    // Handle case where index is -1 (chat not found)
    if (index === -1) {
      console.error("Chat item not found");
      return;
    }

    let defaultChat = Uchat;
    let text = truncate(inputText, 74); // Limiting text length
    let newChatItem;

    // Send message and files
    handleSendMessage1(text, selectedFiles);

    // Clear input after sending
    setInputText(""); // Reset input text
    setSelectedFiles([]); // Clear selected files

    // // Create a new chat item for each new message
    // newChatItem = {
    //   id: `chat_${defaultChat.convo?.length + 1}`, // New message id
    //   me: true, // Current user is sending the message
    //   chat: [text], // Only the new message is included
    //   date: `${currentTime()}`, // Time of the message
    // };

    // // Check if convo exists, and append the new chat item
    // if (!defaultChat.convo || defaultChat.convo.length === 0) {
    //   // If convo doesn't exist, initialize with the first message
    //   defaultChat.convo = [newChatItem];
    // } else {
    //   // Add the new chat item to the convo
    //   defaultChat.convo = [...defaultChat.convo, newChatItem];
    // }

    // // Update the local chat state
    // allChat[index] = defaultChat;
    // setChat([...allChat]);
    setUchat({ ...defaultChat });
    setInputText(""); // Reset the input field

    // Update the Firestore database
    try {
      await updateUserConvo(`${username}_${id}`, newChatItem); // Send only the new chat item
    } catch (error) {
      console.error("Error updating chat in Firestore:", error);
    }
  };

  const onRemoveMessage = (id) => {
    // console.log("deleting id", id);
    const messageRef = doc(db, "Chats", selectedUser.chatId, "messages", id);

    // Update the 'deleted' field to true
    updateDoc(messageRef, {
      deleted: true,
    });
    // let allChat = chat;
    // let cindex = allChat.find((item) => item.id === id);
    // let defaultChat = Uchat;
    // let newConvo = defaultChat.convo;
    // let index = newConvo.findIndex((item) => item.id === id);
    // newConvo[index].chat[idx] = "deleted";
    // allChat[cindex] = defaultChat;
    // setChat([...allChat]);
  };

  const handleMsgAction = async (id, type, item) => {
    // console.log(" id", id, "type", type);

    switch (type) {
      case "edit":
        const messageToEdit = messages.find((msg) => msg.id === id);
        if (messageToEdit) {
          setEditingMessageId(id);
          setInputText(messageToEdit.text);
        }
        break;
      // case "delete":
      //   try {
      //     await deleteDoc(doc(db, "Chats", user.chatId, "messages", messageId));
      //   } catch (error) {
      //     console.error("Error deleting message:", error);
      //   }
      //   break;
      case "copy":
        navigator.clipboard.writeText(messages.find((msg) => msg.id === id)?.text || "");
        break;
      case "reply":
        setReplyingToMessageId(id);
        break;
      case "selectMsg":
        setSelect(true);
        handleSelectMessage(id);
        break;
      case "forwardMsg":
        toggleModal();
        if (item?.contactShare) {
          setShareableContact(item);
        }
        if (item?.fileURLs?.length > 0) {
          setShareFiles(item);
        }
        const messageToShare = messages.find((msg) => msg.id === id);
        if (messageToShare) {
          setForwardMessages(messageToShare.text);
          setSingleMessage(messageToShare.text);
        }
        break;
      default:
        break;
    }
    // const messageRef = doc(db, "Chats", selectedUser.chatId, "messages", id);

    // // Update the 'deleted' field to true
    // updateDoc(messageRef, {
    //   deleted: true,
    // });
  };

  // State for the selected message details (name and message)
  const [selectedMessageDetails, setSelectedMessageDetails] = useState([]);
  // console.log("selectedMessageDetails", selectedMessageDetails);
  const [select, setSelect] = useState(false);
  const [selectedMessageId, setSelectMessageId] = useState([]);
  const [selectedMessages, setSelectedMessages] = useState([]);
  // console.log("selectedMessages", selectedMessages);

  // console.log("selectedMessageId", selectedMessageId);

  // Handle selecting or deselecting a message
  const handleSelectMessage = (id) => {
    const selectedMessage = messages.find((message) => message.id === id);
    if (selectedMessage) {
      setSelectMessageId((prevIds = []) => {
        // Ensure prevIds is an array
        if (prevIds?.includes(id)) {
          // Deselect the message by removing it from the array
          return prevIds.filter((messageId) => messageId !== id);
        } else {
          // Select the message by adding the ID
          return [...prevIds, id];
        }
      });
    }

    if (selectedMessage) {
      setSelectedMessages((prevMessages = []) => {
        // Check if the message is already selected
        const isMessageSelected = prevMessages.some((msg) => msg.messageId === id);

        if (isMessageSelected) {
          // Deselect the message by filtering it out
          return prevMessages.filter((msg) => msg.messageId !== id);
        } else {
          // Select the message by adding messageId and senderId
          return [
            ...prevMessages,
            {
              messageId: id,
              senderId: selectedMessage.senderId,
              text: selectedMessage.text,
              time: new Date(selectedMessage.timestamp.seconds * 1000).toLocaleTimeString("en-US", {
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              }),
            },
          ];
        }
      });

      setSelectedMessageDetails((prevDetails = []) => {
        // Ensure prevDetails is an array
        const isMessageDetailSelected = prevDetails.some(
          (detail) => detail.name === selectedMessage.senderName && detail.message === selectedMessage.content
        );

        if (isMessageDetailSelected) {
          // If message already selected, remove it (deselection)
          return prevDetails.filter(
            (detail) => !(detail.name === selectedMessage.senderName && detail.message === selectedMessage.content)
          );
        } else {
          // Add unique message details
          return [...prevDetails, { name: selectedMessage.senderName, message: selectedMessage.content }];
        }
      });
    }
  };

  // Handle editing a message
  const handleEditMessage = async () => {
    if (editingMessageId && inputText.trim()) {
      try {
        await updateDoc(doc(db, "Chats", user.chatId, "messages", editingMessageId), {
          text: inputText,
          edited: true,
        });
        setEditingMessageId(null);
        setInputText("");
        setIncomingID(false);
      } catch (error) {
        console.error("Error updating message:", error);
        setIncomingID(false);
      }
    }
  };
  const repliedMessage = messages.find((msg) => msg.id === replyingToMessageId);
  // console.log("repliedMessage", repliedMessage);
  // Handle replying to a message
  const handleReply = (reply) => {
    const replyingToUserId = repliedMessage?.senderId; // Get the userId of the message you're replying to
    const replyingToUsername = repliedMessage?.senderName; // Get the username of the message you're replying to

    handleSendMessage(
      ` ${messages.find((msg) => msg.id === replyingToMessageId)?.text} `,
      `${reply}`,
      replyingToUserId,
      replyingToUsername
    );
    setReplyingToMessageId(null);
  };
  const repliedMessageRef = useRef(null); // Ref for the replied message

  // Scroll to the replied message
  useEffect(() => {
    if (replyingToMessageId && repliedMessageRef.current) {
      repliedMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [replyingToMessageId]);
  const handleClearReply = () => {
    setReplyingToMessageId(null);
  };

  const scrollToMessage = (messageId) => {
    if (messageRefs.current[messageId]) {
      messageRefs.current[messageId].scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleCancelSelect = () => {
    setSelect(false);
    setSelectMessageId([]);
    setSelectedMessages([]);
  };

  const handleCopyMessages = () => {
    // Format the selected messages' text and time into a single string
    const messagesToCopy = selectedMessages.map((message) => ` ${message.text}  ${message.time}`).join("\n"); // Join each message with a new line

    // Copy the formatted string to the clipboard
    navigator.clipboard
      .writeText(messagesToCopy)
      .then(() => {
        handleCancelSelect();
        setShowToastMessage("Messages copied to clipboard!");
        setShowToast(!showToast);
        setShowToastTheme("green");
        // console.log("Messages copied to clipboard!");
      })
      .catch((err) => {
        console.error("Error copying text: ", err);
      });
  };

  const handleSelectedMessagesDelete = () => {
    // Iterate over the selected messages and mark them as deleted
    selectedMessages.forEach((message) => {
      const messageRef = doc(db, "Chats", selectedUser.chatId, "messages", message.messageId);

      // Update the 'deleted' field to true
      updateDoc(messageRef, {
        deleted: true,
      })
        .then(() => {
          handleCancelSelect();

          // console.log(`Message with id ${message.messageId} marked as deleted.`);
        })
        .catch((error) => {
          console.error("Error deleting message: ", error);
        });
    });
  };

  useEffect(() => {
    // Focus the textarea when replyingToMessageId and repliedMessage are true
    if (replyingToMessageId && repliedMessage) {
      textareaRef.current.focus();
    }
    if (editingMessageId) {
      textareaRef.current.focus();
    }
  }, [replyingToMessageId, repliedMessage, editingMessageId]);

  const chatBodyClass = classNames({
    "nk-chat-body": true,
    "show-chat": mobileView,
    "profile-shown": sidebar && window.innerWidth > 1550,
  });

  return (
    <React.Fragment>
      {Object.keys(Uchat).length > 0 && (
        <div className={chatBodyClass}>
          <div className="nk-chat-head">
            <ul className="nk-chat-head-info">
              <li className="nk-chat-body-close" onClick={() => setMobileView(false)}>
                <a
                  href="#hide-chat"
                  onClick={(ev) => {
                    ev.preventDefault();
                  }}
                  className="btn btn-icon btn-trigger nk-chat-hide ms-n1"
                >
                  <Icon name="arrow-left"></Icon>
                </a>
              </li>
              <li className="nk-chat-head-user">
                <div className="user-card">
                  {Uchat?.profilePic ? (
                    <img
                      src={Uchat?.profilePic} // URL of the DJ's profile image
                      alt="Profile"
                      className="user-avatar"
                      style={{ width: "50px", height: "50px", borderRadius: "50%", objectFit: "cover" }} // Ensure the image is rounded
                    />
                  ) : (
                    <UserAvatar
                      text={getInitiatals(Uchat?.name)}
                      theme="primary"
                      style={{ width: "40px", height: "40px", borderRadius: "50%" }}
                    ></UserAvatar>
                  )}
                  <div className="user-info">
                    <div className="lead-text text-capitalize">
                      {Uchat?.name} {Uchat?.id === currentUser?.id ? "(You)" : ""}
                    </div>
                    <div className="sub-text">{Uchat.active === true ? "Active Now" : ` `}</div>
                  </div>
                </div>
              </li>
            </ul>
            <ul className="nk-chat-head-tools">
              {/* <li className="d-none d-sm-block">
                <UncontrolledDropdown>
                  <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger text-primary">
                    <Icon name="setting-fill"></Icon>
                  </DropdownToggle>
                  <DropdownMenu end className="dropdown-menu">
                    <ul className="link-list-opt no-bdr">
                      <li>
                        <DropdownItem
                          tag="a"
                          className="dropdown-item"
                          disabled={Uchat.archive}
                          href="#dropdown"
                          onClick={(ev) => {
                            ev.preventDefault();
                            propAction(id, "archive");
                          }}
                        >
                          <Icon name="archive"></Icon>
                          <span>Make as Archive</span>
                        </DropdownItem>
                      </li>
                      <li>
                        <DropdownItem
                          tag="a"
                          className="dropdown-item"
                          href="#dropdown"
                          onClick={(ev) => {
                            ev.preventDefault();
                            deleteConvo(id);
                            setSelectedId(null);
                          }}
                        >
                          <Icon name="cross-c"></Icon>
                          <span>Remove Conversion</span>
                        </DropdownItem>
                      </li>
                    </ul>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </li> */}
              <li className="me-n1 me-md-n2">
                <a
                  href="#alert"
                  onClick={(e) => {
                    e.preventDefault();
                    toggleMenu();
                  }}
                  className="btn btn-icon btn-trigger text-primary chat-profile-toggle"
                >
                  <Icon name="alert-circle-fill"></Icon>
                </a>
              </li>
            </ul>
          </div>
          <SimpleBar className="nk-chat-panel" scrollableNodeProps={{ ref: messagesEndRef }}>
            {messages?.map((item, idx) => {
              if (item.senderId === currentUser?.id) {
                return (
                  <MeChat
                    key={idx}
                    item={item}
                    chat={Uchat}
                    onRemoveMessage={onRemoveMessage}
                    handleMsgAction={handleMsgAction}
                    messageRefs={messageRefs}
                    scrollToMessage={scrollToMessage}
                    select={select}
                    handleSelectMessage={handleSelectMessage}
                    selectedMessageId={selectedMessageId}
                    chatItemClick={chatItemClick}
                    uploading={uploading}
                    uploadingFiles={uploadingFiles}
                    lastMessageId={messages[messages?.length - 1]?.id}
                    handleCancelUpload={handleCancelUpload}
                  ></MeChat>
                );
                // } else if (item.meta) {
                //   return <MetaChat key={idx} item={item.meta.metaText}></MetaChat>;
              } else {
                return (
                  <YouChat
                    key={idx}
                    item={item}
                    chat={Uchat}
                    handleMsgAction={handleMsgAction}
                    messageRefs={messageRefs}
                    scrollToMessage={scrollToMessage}
                    select={select}
                    handleSelectMessage={handleSelectMessage}
                    selectedMessageId={selectedMessageId}
                    chatItemClick={chatItemClick}
                  ></YouChat>
                );
              }
            })}
          </SimpleBar>
          {selectedFiles.length > 0 && (
            <ul
              style={{
                display: "flex",
                gap: "10px",
                overflowX: "auto",
                padding: "10px",
                listStyleType: "none",
                height: 230,
                maxHeight: 230,
              }}
            >
              {selectedFiles.length > 0 &&
                selectedFiles.map((file, index) => {
                  const fileType = file.type.split("/")[0]; // Get the file type (e.g., "image", "video", etc.)

                  return (
                    <li
                      key={index}
                      style={{
                        position: "relative",
                        border: "1px solid #ccc",
                        borderRadius: "8px",
                        padding: "5px",
                        minWidth: "100px",
                        minHeight: "100px",
                        overflow: "hidden",
                      }}
                    >
                      {/* Image Preview */}
                      {fileType === "image" && (
                        <div>
                          <img
                            src={URL.createObjectURL(file)}
                            alt={file.name}
                            style={{ width: "100px", height: "100px", objectFit: "cover", borderRadius: "5px" }}
                          />
                        </div>
                      )}

                      {/* Video Preview */}
                      {fileType === "video" && (
                        <div>
                          <video
                            width="100"
                            height="100"
                            controls
                            src={URL.createObjectURL(file)}
                            style={{ borderRadius: "5px" }}
                          >
                            Your browser does not support the video tag.
                          </video>
                        </div>
                      )}

                      {/* Non-media File (e.g., PDF, docs) */}
                      {fileType !== "image" && fileType !== "video" && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100px",
                            width: "100px",
                          }}
                        >
                          <Icon name="file" /> {/* Use any icon for non-media files */}
                          <span style={{ marginLeft: "5px" }}>{file.name}</span>
                        </div>
                      )}

                      {/* Cancel (Remove) Button */}
                      <button
                        onClick={() => handleRemoveFile(index)}
                        style={{
                          position: "absolute",
                          top: "5px",
                          right: "5px",
                          backgroundColor: "red",
                          color: "white",
                          border: "none",
                          borderRadius: "50%",
                          width: "20px",
                          height: "20px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                        }}
                      >
                        &times;
                      </button>
                    </li>
                  );
                })}
            </ul>
          )}

          <div className="nk-chat-editor p-0">
            <div className="nk-chat-editor-upload  ms-n1">
              {/* <Button
                size="sm"
                className={`btn-icon btn-trigger text-primary toggle-opt ${chatOptions ? "active" : ""}`}
                onClick={() => onChatOptions()}
              >
                <Icon name="plus-circle-fill"></Icon>
              </Button> */}
              <div
                className={`${
                  theme.skin === "dark" ? "chat-upload-option-dark chat-upload-option" : "chat-upload-option"
                } ${chatOptions ? "expanded" : ""}`}
              >
                <ul className="">
                  <li>
                    <a
                      href="#img"
                      onClick={(ev) => {
                        ev.preventDefault();
                        document.getElementById("fileInput").click(); // Trigger file input click
                      }}
                    >
                      <Icon name="folder" />
                    </a>
                    <input
                      type="file"
                      id="fileInput"
                      style={{ display: "none" }}
                      multiple
                      onChange={handleFileChange} // Call file change handler
                    />
                  </li>
                  {/* <li>
                    <a
                      href="#camera"
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                    >
                      <Icon name="camera-fill"></Icon>
                    </a>
                  </li> */}
                  {/* <li>
                    <a
                      href="#mic"
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                    >
                      <Icon name="mic"></Icon>
                    </a>
                  </li>
                  <li>
                    <a
                      href="#grid"
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                    >
                      <Icon name="grid-sq"></Icon>
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>

            <div className="nk-chat-editor-form">
              {/* <div className="form-control-wrap"> */}
              {replyingToMessageId && repliedMessage && (
                <div className="replied-message" ref={repliedMessageRef}>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <h6 className="replied-message-title" style={{ fontSize: "0.8rem", gap: 10 }}>
                      <Icon name="reply-fill" className="mr-2" />
                      <span>Replying to:</span>
                    </h6>
                    <Button className="clear-icon" onClick={handleClearReply} size="sm" close>
                      <Icon name="cross" className="mr-2" />
                    </Button>
                  </div>
                  <p
                    className="replied-message-text"
                    onClick={() => scrollToMessage(replyingToMessageId)}
                    style={{ cursor: "pointer" }} // Add some styles to indicate it's clickable
                  >
                    {/* {repliedMessage.text} */}
                    {repliedMessage.text
                      ?.slice(0, 150)
                      .split(" ")
                      .map((word, i) =>
                        word.match(/(https?:\/\/[^\s]+)/g) ? (
                          <span key={i} href={word} target="_blank" rel="noopener noreferrer">
                            {word}
                            {repliedMessage.text.length > 150 ? " ..." : ""}
                          </span>
                        ) : (
                          <span key={i}>{word}</span>
                        )
                      )}
                    {repliedMessage?.text?.length > 150 ? "..." : ""}
                  </p>
                </div>
              )}

              {select ? (
                <div className="replied-message">
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>
                      <h6 className="replied-message-title" style={{ fontSize: "0.8rem", gap: 10 }}>
                        <span>{selectedMessages?.length} messages selected</span>
                      </h6>
                      <p style={{ fontSize: 12, marginTop: -5 }}>{process.env.REACT_APP_NAME} chat</p>
                    </div>
                    <Button className="clear-icon" onClick={handleCancelSelect} size="sm" close>
                      <Icon name="cross" className="mr-2" />
                    </Button>
                  </div>

                  {/* Add Copy Button */}
                  <Button className="copy-icon" size="lg" onClick={handleCopyMessages}>
                    <Icon name="copy" style={{ height: 20, width: 20 }} />
                  </Button>

                  <Button
                    className="trash-icon"
                    size="lg"
                    close
                    disabled={selectedMessages.some((message) => message.senderId === id)}
                    onClick={handleSelectedMessagesDelete}
                  >
                    <Icon name="trash" style={{ height: 20, width: 20 }} />
                  </Button>
                  <Button className="trash-icon" size="lg" close onClick={toggleModal}>
                    <Icon name="send" style={{ height: 20, width: 20 }} />
                  </Button>
                </div>
              ) : (
                <textarea
                  ref={textareaRef}
                  className=" form-control-simple form-control no-resize p-0"
                  rows="2"
                  id="default-textarea"
                  onChange={(e) => onInputChange(e)}
                  value={inputText}
                  placeholder="Type your message..."
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                      e.preventDefault();

                      if (editingMessageId) {
                        // If editing a message, trigger the edit functionality
                        handleEditMessage(inputText);
                      } else if (replyingToMessageId) {
                        // If replying to a message, trigger the reply functionality
                        handleReply(inputText);
                      } else {
                        // Normal submit if neither editing nor replying
                        onTextSubmit(e);
                      }
                    }
                  }}
                ></textarea>
              )}
              {/* </div> */}
            </div>
            {!select && (
              <ul className="nk-chat-editor-tools g-2 p-2">
                <li>
                  <Button size="sm" className="btn-icon btn-trigger text-primary" onClick={toggleEmojiPicker}>
                    <Icon name="happyf-fill" />
                  </Button>
                </li>
                {showEmojiPicker && (
                  <div
                    ref={emojiPickerRef}
                    style={{
                      position: "absolute",
                      bottom: "60px", // Adjust as needed
                      right: "0",
                      zIndex: 1,
                    }}
                  >
                    <EmojiPicker onEmojiClick={handleEmojiClick} />
                  </div>
                )}
                <li>
                  <Button color="primary" onClick={(e) => onTextSubmit(e)} className="btn-round btn-icon">
                    <Icon name="send-alt"></Icon>
                  </Button>
                </li>
              </ul>
            )}
          </div>

          <ChatSideBar
            id={id}
            sidebar={sidebar}
            chat={Uchat}
            currentUser={currentUser}
            setSelectedId={setSelectedId}
            sharedPhotos={sharedPhotos}
          />

          {window.innerWidth < 1550 && sidebar && (
            <div onClick={() => toggleMenu()} className="nk-chat-profile-overlay" />
          )}
        </div>
      )}
      {/* Render the CommonToaster */}
      <CommonToaster
        showToast={showToast}
        message={showToastMessage} // Pass the dynamic message to the toast
        toggleToast={toggleToast}
        theme={showToastTheme}
      />

      {/* ShareContactModal Component */}
      <ShareMessageModal
        isOpen={isModalOpen} // Pass the state to control modal visibility
        toggle={toggleModal} // Pass the toggle function to close the modal
        forwardMessages={forwardMessages || singleMessage} // Pass the current user prop if needed
        currentUser={currentUser}
        shareableContact={shareableContact}
        shareFiles={shareFiles}
      />
    </React.Fragment>
  );
};

export default ChatBody;
