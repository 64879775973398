import { useFormik } from "formik";
import { useEffect, useState } from "react";
import ReactSelect from "../../../src/components/ReactSelect/ReactSelect";
import { Col, Input, Label, Row } from "reactstrap";
import { Icon, RSelect } from "../../components/Component";
import NavigationFooter from "../components/NavigationFooter";
import * as Yup from "yup";
import UploadImage from "../components/UploadImage";
import { useMutation, useQuery } from "react-query";
import {
  getCities,
  getClientsList,
  getCountries,
  getStates,
} from "../../http/getApi";
import { useParams } from "react-router";
import { editClientDetails } from "../../http/editApi";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { transformData } from "../../utils/Utils";

const Profile = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { id } = useParams();

  const {
    data: country,
    isLoading: countryLoading,
    isError: countryError,
    refetch,
  } = useQuery({
    queryKey: ["get-country"],
    queryFn: () => getCountries(),
    staleTime: Infinity,
  });

  console.log("country:", country);

  const countriesOptions = transformData({
    dataArray: country?.data?.countries || [],
    label: "name",
    value: "id",
    isLoading: countryLoading,
  });
  let initialValues = {
    username: "",
    first_name: "",
    last_name: "",
    organization_name: "",
    work_phone_number: "",
    phone_number: "",
    email: "",
    password: "",
    address_1: "",
    address_2: "",
    country: "",
    city: "",
    state: "",
    files: "",
    zipcode: "",
    website: "",
  };
  const schema = Yup.object().shape({
    work_phone_number: Yup.string()
      .nullable()
      .matches(
        /^[0-9]{9,13}$/,
        "Work mobile no must be between 9 to 13 digits."
      ),
    phone_number: Yup.string()
      .nullable()
      .matches(
        /^[0-9]{9,13}$/,
        "Home phone no must be between 9 to 13 digits."
      ),
    zipcode: Yup.string()
      .nullable()
      .matches(/^[a-zA-Z0-9]{5,10}$/, "Zip-code must be at least 5 digits."),
    website: Yup.string()
      .nullable()
      .matches(
        /^(https?:\/\/)?([\w-]+\.)+[\w-]{2,4}(:\d+)?(\/.*)?$/,
        "Website must be a valid URL."
      ),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: (value) => {
      editClientFromList(value);
    },
  });
  let countryId = formik.values?.country;
  const {
    data: states,
    isLoading: statesLoading,
    isError: statesError,
  } = useQuery({
    queryKey: ["get-states-by-country", countryId],
    queryFn: () => getStates({ id: countryId }),
    staleTime: Infinity,
  });

  const statesOptions = transformData({
    dataArray: states?.data?.states || [],
    label: "name",
    value: "id",
    isLoading: statesLoading,
  });

  let stateId = formik.values?.state;

  const {
    data: cities,
    isLoading: citiesLoading,
    isError: citiesError,
  } = useQuery({
    queryKey: ["get-cities-by-state", stateId],
    queryFn: () => getCities({ id: stateId }),
    staleTime: Infinity,
  });

  const citiesOptions = transformData({
    dataArray: cities?.data?.cities || [],
    label: "name",
    value: "id",
    isLoading: statesLoading,
  });
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  function togglePasswordVisibility() {
    setIsPasswordVisible(!isPasswordVisible);
  }

  const {
    data: clientsList,
    isLoading: clientsListIsLoading,
    isError: clientsListIsError,
  } = useQuery({
    queryKey: ["get-clients-by-id"],
    queryFn: () => getClientsList(),
  });

  const {
    mutate: editClientMutation,
    isLoading: isClientEditing,
    error: clientEditError,
  } = useMutation({
    mutationKey: ["Edit-client-by-ID"],
    mutationFn: (value) => editClientDetails(value),
  });

  const editClientFromList = (value) => {
    try {
      editClientMutation(value);
    } catch (error) {
      console.error("Error editing client:", error);
    }
  };

  useEffect(() => {
    if (!clientsListIsLoading) {
      formik.setValues((prevData) => {
        return {
          ...prevData,
          ...clientsList?.data?.clients[0],
          country: parseInt(clientsList?.data?.clients[0]?.country),
          state: parseInt(clientsList?.data?.clients[0]?.state),
          city: parseInt(clientsList?.data?.clients[0]?.city),
          files: clientsList?.data?.clients[0]?.profile_image_path || "",
          zipcode: clientsList?.data?.clients[0]?.zip_code,
        };
      });
    }
  }, [clientsListIsLoading]);

  const handelCountryDropdown = (e) => {
    formik.setFieldValue("country", e.value);
  };
  const handelStateDropdown = (e) => {
    formik.setFieldValue("state", e.value);
  };

  const handelCityDropdown = (e) => {
    formik.setFieldValue("city", e.value);
  };

  return (
    <>
      <div className="px-2 mt-4">
        <form onSubmit={formik.handleSubmit}>
          {clientsListIsLoading ? (
            <Row>
              <Col md={6} className="border p-3 bg-white">
                <Row>
                  <Col md={6} className="border p-3 bg-white">
                    <ShimmerThumbnail height={250} width={300} rounded />;
                    <ShimmerThumbnail height={40} width={300} rounded />
                    <ShimmerThumbnail height={40} width={300} rounded />
                    <ShimmerThumbnail height={40} width={300} rounded />
                  </Col>
                  <Col md={6} className="border p-3 bg-white">
                    <ShimmerThumbnail height={50} width={300} rounded />
                    <ShimmerThumbnail height={50} width={300} rounded />
                    <ShimmerThumbnail height={50} width={300} rounded />
                    <ShimmerThumbnail height={50} width={300} rounded />
                    <ShimmerThumbnail height={50} width={300} rounded />
                    <ShimmerThumbnail height={50} width={300} rounded />
                  </Col>
                </Row>
              </Col>
              <Col md={6} className="border p-3 bg-white">
                <Row>
                  <ShimmerThumbnail height={40} width={600} rounded />
                  <ShimmerThumbnail height={40} width={600} rounded />
                  <ShimmerThumbnail height={40} width={600} rounded />
                  <ShimmerThumbnail height={40} width={600} rounded />
                  <Row className="mt-5">
                    <Col md={4}>
                      <ShimmerThumbnail height={40} width={200} rounded />
                    </Col>
                    <Col md={4}>
                      <ShimmerThumbnail height={40} width={200} rounded />
                    </Col>
                    <Col md={4}>
                      <ShimmerThumbnail height={40} width={200} rounded />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <ShimmerThumbnail height={40} width={300} rounded />
                    </Col>
                    <Col md={6}>
                      <ShimmerThumbnail height={40} width={300} rounded />
                    </Col>
                  </Row>
                </Row>
              </Col>
            </Row>
          ) : (
            <Row className="p-2 mt-2 ">
              <Col md={6} className="border p-3 bg-white">
                <Row>
                  <Col md={6}>
                    <UploadImage
                      label="Upload profile picture"
                      name={"files"}
                      formik={formik}
                    />
                  </Col>
                  <Col md={6}>
                    <div>
                      <Label for="firstName">First Name </Label>
                      <Input
                        type="text"
                        id="first_name"
                        name="first_name"
                        value={formik.values.first_name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Enter first name"
                      />
                      {formik.errors.first_name &&
                        formik.touched.first_name && (
                          <p style={{ color: "red" }}>
                            {" "}
                            {formik.errors.first_name}
                          </p>
                        )}
                    </div>

                    <div className="mt-4">
                      <Label htmlFor="lastName">Last Name</Label>
                      <Input
                        type="text"
                        id="last_name"
                        value={formik.values.last_name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="last_name"
                        placeholder="Enter last name"
                      />
                      {formik.errors.last_name && formik.touched.last_name && (
                        <p style={{ color: "red" }}>
                          {" "}
                          {formik.errors.last_name}
                        </p>
                      )}
                    </div>

                    <div className="mt-4">
                      <Label for="organization">Organization Name </Label>
                      <Input
                        type="text"
                        id="organization_name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.organization_name}
                        name="organization_name"
                        placeholder="Enter organization name"
                      />
                      {formik.errors.organization_name &&
                        formik.touched.organization_name && (
                          <p style={{ color: "red" }}>
                            {formik.errors.organization_name}
                          </p>
                        )}
                    </div>
                  </Col>
                </Row>

                {/* Second row  */}
                <Row className="mt-4">
                  <Col md={6}>
                    <div>
                      <Label for="email">Email</Label>
                      <Input
                        id="email"
                        disabled
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        type="email"
                        placeholder="Enter email"
                        name="email"
                      />
                      {formik.errors.email && formik.touched.email && (
                        <p style={{ color: "red" }}> {formik.errors.email}</p>
                      )}
                    </div>
                  </Col>
                  <Col md={6}>
                    <div>
                      <Label for="website">Website</Label>
                      <Input
                        id="website"
                        //type="website"
                        value={formik.values.website}
                        placeholder="Enter website"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="website"
                      />
                    </div>
                    {formik.touched.website && formik.errors.website && (
                      <div style={{ color: "red", fontSize: "0.875rem" }}>
                        {formik.errors.website}
                      </div>
                    )}
                  </Col>
                </Row>

                <h5 className="my-4">Login Details </h5>
                {/* Second row  */}
                <Row>
                  <Col md={6}>
                    <div className="form-control-wrap">
                      <Label for="username">Username</Label>
                      <div className="form-icon form-icon-right mt-4">
                        <Icon name={"icon ni ni-user"} className="icon mt-2" />
                      </div>
                      <Input
                        id="username"
                        disabled
                        value={formik.values.username}
                        type="username"
                        placeholder="Enter username"
                        name="username"
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-control-wrap">
                      <Label
                        for="pass"
                        className="d-flex justify-content-between align-items-center"
                      >
                        <span>Password</span>
                      </Label>
                      <div className="form-icon form-icon-right mt-4">
                        <Icon
                          name={
                            isPasswordVisible ? "ni ni-eye" : "ni ni-eye-off"
                          }
                          className="icon mt-2"
                          onClick={togglePasswordVisibility}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                      <Input
                        type={isPasswordVisible ? "text" : "Password"}
                        id="password"
                        name="password"
                        className="form-control"
                        value={formik.values.password}
                        placeholder={
                          isPasswordVisible ? "Enter password" : "● ● ● ● ● ● ●"
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col md={6} className="border p-3 bg-white border-start-0">
                {/* third row */}
                <h5 className="p-0 mb-2">Telephone Numbers</h5>
                <Row>
                  <Col>
                    <div className="pt-2">
                      <Label for="email" className="">
                        Home Phone
                      </Label>
                      <Input
                        id="phone_number"
                        type="number"
                        onKeyDown={(evt) =>
                          ["e", "E", "+", "-"].includes(evt.key) &&
                          evt.preventDefault()
                        }
                        onChange={formik.handleChange}
                        value={formik.values.phone_number}
                        onBlur={formik.handleBlur}
                        placeholder="Enter Home Phone"
                        name="phone_number"
                      />
                      {formik.errors.phone_number &&
                        formik.touched.phone_number && (
                          <p style={{ color: "red" }}>
                            {formik.errors.phone_number}
                          </p>
                        )}
                    </div>
                  </Col>
                </Row>
                {/* forth row  */}
                <Row className="mt-4">
                  <Col>
                    <div className="p1-2">
                      <Label for="email" className="">
                        Work Phone
                      </Label>
                      <Input
                        id="work_phone_number"
                        type="number"
                        placeholder="Enter Work Phone"
                        value={formik.values.work_phone_number}
                        onKeyDown={(evt) =>
                          ["e", "E", "+", "-"].includes(evt.key) &&
                          evt.preventDefault()
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="work_phone_number"
                      />
                      {formik.errors.work_phone_number &&
                        formik.touched.work_phone_number && (
                          <p style={{ color: "red" }}>
                            {formik.errors.work_phone_number}
                          </p>
                        )}
                    </div>
                  </Col>
                </Row>
                {/* fifth row */}
                <h5 className="mt-5">Mailing Address</h5>
                <Row className="pt-3">
                  <Col md={4}>
                    <div className="">
                      <Label for="address1">Address Line1</Label>
                      <Input
                        id="address_1"
                        value={formik.values.address_1}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Enter address line1"
                        name="address_1"
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div>
                      <Label for="address2">Address Line2</Label>
                      <Input
                        id="address_2"
                        value={formik.values.address_2}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Enter address line2"
                        name="address_2"
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div>
                      <Label for="zipcode">Zip-code</Label>
                      <Input
                        id="zipcode"
                        type="number"
                        value={formik.values.zipcode}
                        placeholder="Enter zip code"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        onKeyDown={(evt) =>
                          ["e", "E", "+", "-"].includes(evt.key) &&
                          evt.preventDefault()
                        }
                        name="zipcode"
                      />
                      {formik.errors.zipcode && formik.touched.zipcode && (
                        <p style={{ color: "red" }}> {formik.errors.zipcode}</p>
                      )}
                    </div>
                  </Col>
                </Row>
                {/* sixth row  */}
                <Row className="mt-2">
                  <Col md={4}>
                    <div>
                      <Label for="country">Select Country</Label>
                      {console.log("country: ", formik.values.country_name)}
                      <ReactSelect
                        options={countriesOptions}
                        id="country"
                        name="country"
                        value={formik.values.country}
                        onChange={(e) => handelCountryDropdown(e)}
                        onBlur={() => formik.setFieldTouched("country", true)}
                        invalid={
                          formik.errors.country && formik.touched.country
                        }
                      />
                      {formik.errors.country && formik.touched.country && (
                        <p style={{ color: "red" }}>{formik.errors.country}</p>
                      )}
                    </div>
                  </Col>
                  <Col md={4}>
                    <div>
                      <Label for="state">Select State</Label>
                      <ReactSelect
                        options={statesOptions}
                        id="state"
                        name="state"
                        value={formik.values.state}
                        onChange={(e) => handelStateDropdown(e)}
                        onBlur={() => formik.setFieldTouched("state", true)}
                        // invalid={formik.errors.state && formik.touched.state}
                      />
                      {/* {formik.touched.state && Number.isNaN(formik.values.state) && (
                        <p style={{ color: "red" }}>state  field is required.</p>
                      )} */}
                    </div>
                  </Col>
                  <Col md={4}>
                    <div>
                      <Label for="city">Select City</Label>
                      <ReactSelect
                        options={citiesOptions}
                        id="city"
                        name="city"
                        value={formik.values.city}
                        onChange={(e) => {
                          handelCityDropdown(e);
                        }}
                        // onChange={(e) => handelCityDropdown(e)}
                        // onChange={(e) => {handelCityDropdown(e); getCoordinates()}}
                        onBlur={() => formik.setFieldTouched("city", true)}
                        invalid={formik.errors.city && formik.touched.city}
                      />
                      {/* {formik.touched.city && Number.isNaN(formik.values.city) && (
                        <p style={{ color: "red" }}>City  field is required.</p>
                      )} */}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}

          <NavigationFooter formik={formik} isLoading={isClientEditing} />
        </form>
      </div>
    </>
  );
};
export default Profile;
