import Swal from "sweetalert2";
import { dataURLtoFile, getCookie } from "../utils/Utils";

let token = process.env.REACT_APP_AUTH_TOKEN;

// edit API to edit client  details ====================g=============
export const editClientDetails = async (value) => {
  console.log("value:", value);

  let values = value;
  const formData = new FormData();

  formData.append("id", values.id);
  const base64 = values.files.split(",");

  if (values.files) {
    var base64regex =
      /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;

    if (base64regex.test(base64[1])) {
      const userImage = dataURLtoFile(values.files, "userImage");
      formData.append("files", userImage);
    }
  } else {
    formData.append("files", "");
  }
  if (values.username) {
    formData.append("username", values.username);
  }
  if (values.first_name) {
    formData.append("first_name", values.first_name);
  }
  if (values.last_name) {
    formData.append("last_name", values.last_name);
  }
  if (values.email) {
    formData.append("email", values.email);
  }
  if (values.work_phone_number) {
    formData.append("work_phone_number", values.work_phone_number);
  }

  if (values.password) {
    formData.append("password", values.password);
  }
  if (values.address_1) {
    formData.append("address_1", values.address_1);
  }
  if (values.address_2) {
    formData.append("address_2", values.address_2);
  }

  if (values.state) {
    formData.append("state", values.state);
  }
  if (values.country) {
    formData.append("country", values.country);
  }

  if (values.phone_number) {
    formData.append("phone_number", values.phone_number);
  }
  if (values.zipcode) {
    formData.append("zip_code", values.zipcode);
  }
  if (values.city) {
    formData.append("city", values.city);
  }

  if (values.organization_name) {
    formData.append("organization_name", values.organization_name);
  }
  if (values.website) {
    formData.append("website", values.website);
  }
  const requestOptions = {
    method: "POST",
    headers: {
      authToken: getCookie("authToken", token),
    },
    body: formData,
  };

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}api/v1/client/update-client`,
    requestOptions
  );

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while editing client ");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }
  const res = await response.json();
  if (res.status) {
    Swal.fire({
      icon: "success",
      title: "Successful",
      text:
        res?.message === "Client details updated successfully"
          ? "Profile Updated Successfully"
          : res?.message,
      focusConfirm: false,
    });
  } else {
    Swal.fire({
      icon: "error",
      title: "Error",
      text: res?.errors[0],
      focusConfirm: false,
    });
  }
  return res;
};
