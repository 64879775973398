import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { Container, Row, Col, Button } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom"; // Import useLocation hook
import { useTheme, useThemeUpdate } from "../provider/Theme";
import Toggle from "../sidebar/Toggle";
import { Icon, LinkItem } from "../../components/Component";
import logo from "../../images/favicon.png";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { getCookie } from "../../utils/Utils";
import Cookies from "js-cookie";
import User from "./User";
import Notification from "../../components/partials/default/notification/Notification";
import AppDropdown from "./dropdown/app/App";
import ChatNotification from "./dropdown/notification/ChatNotification";

const AppHeader = ({ fixed, className, app, id }) => {
  const location = useLocation(); // Get the current location
  const navigate = useNavigate();
  const dashboard = "client-dash";

  const theme = useTheme();
  const themeUpdate = useThemeUpdate();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [display, setDisplay] = useState(window.innerWidth >= 768);

  const headerClass = classNames({
    "nk-header": true,
    "nk-header-fixed": fixed,
    [`is-light`]: theme.header === "white",
    [`is-${theme.header}`]:
      theme.header !== "white" && theme.header !== "light",
    [`${className}`]: className,
  });

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setWindowWidth(width);
      setDisplay(width >= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const routes = {
    home: "/home",
    events: "/my-events",
    profile: "/profile",
    contact: "/contact-us",
  };
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const fetchData = async () => {
    try {
      // setLoading(true);
      // const formdata = new FormData();
      // formdata.append("field_key", "client_home_logo");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/v1/public/get-site-settings`,
        {
          method: "POST",
          // body: formdata,
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.json();
      console.log(responseData);
      const now = new Date();
      const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000);
      Cookies.set(
        "favicon",
        JSON.stringify(responseData?.data?.site_settings?.meta?.favicon),
        {
          expires: expires,
        }
      );
      setData(responseData?.data?.site_settings?.meta?.client_home_logo);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      // setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    getCookie("authToken");
  }, []);

  console.log("data", data);

  const setLogout = () => {
    const now = new Date();
    const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000);

    Cookies.set("logoutURL", process.env.REACT_APP_ACCOUNT_URL, {
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      expires: expires,
    });
    Cookies.set("dashboard", "client", {
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      expires: expires,
    });
    const logoutUrl = `${process.env.REACT_APP_ACCOUNT_URL}logout`;
    window.location.href = logoutUrl;
  };
  return (
    <>
      <div className={headerClass}>
        <Container fluid>
          <Row className="d-flex align-items-center justify-content-between">
            {data ? (
              <Col xs="auto" style={{ cursor: "pointer" }}>
                <Toggle
                  className="nk-nav-toggle nk-quick-nav-icon d-xl-none ms-n1 mx-2 py-2"
                  icon="menu"
                  click={themeUpdate.sidebarVisibility}
                />
                <img
                  src={`${process.env.REACT_APP_API_URL}` + data}
                  alt="DJ_logo"
                  className="my-1 object-fit-cover"
                  onClick={() => navigate(routes.home)}
                  //we add command here....
                  // onError={(e) => {
                  //   e.target.onerror = null;
                  //   e.target.src = "path_to_placeholder_image";
                  // }}
                  style={{ width: "78px" }}
                />
              </Col>
            ) : (
              <Col xs="auto" style={{ cursor: "pointer" }}>
                <div>
                  <ShimmerThumbnail
                    height={50}
                    width={40}
                    className="m-0 mt-1"
                    rounded
                  />
                </div>
              </Col>
            )}
            {/* {data ? (
              <Col xs="auto" style={{ cursor: "pointer" }}>
                  <img
                    src={`${process.env.REACT_APP_API_URL}` + data}
                    alt="DJ_logo"
                    // width={70}
                    className="my-2 w-100px"
                    onClick={() => navigate(routes.home)}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "path_to_placeholder_image"; 
                    }}
                  />
              </Col>
            ) : (
              <Col xs="auto">
                <div>
                  <ShimmerThumbnail
                    height={75}
                    width={40}
                    className="m-0 mt-1"
                    rounded
                  />
                </div>
              </Col>
            )} */}
            {windowWidth < 768 && (
              <Col xs="auto" style={{ cursor: "pointer" }}>
                <p
                  onClick={() => setDisplay(!display)}
                  style={{ fontSize: "1.5rem" }}
                >
                  <>
                    {!display && <em className="icon ni ni-more-v"></em>}
                    {display && <em className="icon ni ni-cross"></em>}
                  </>
                </p>
              </Col>
            )}
            {display && (
              <Col
                xs="auto"
                lg="auto"
                className="nk-header-tools nk-quick-nav d-flex flex-wrap"
              >
                {/* <Button
                  color="primary"
                  type="button"
                  onClick={() => navigate(routes.home)}
                  className={location.pathname === routes.home ? "active" : ""} // Highlight the active link
                >
                  <>
                    <Icon name="icon ni ni-home" /> <span>Home</span>
                  </>
                </Button>

                <Button
                  color="primary"
                  type="button"
                  onClick={() => navigate(routes.events)}
                  className={
                    location.pathname === routes.events ? "active" : ""
                  } // Highlight the active link
                >
                  <>
                    <Icon name="icon ni ni-award" /> <span>Events</span>
                  </>
                </Button>

                <Button
                  color="primary"
                  type="button"
                  onClick={() => navigate(routes.profile)}
                  className={
                    location.pathname === routes.profile ? "active" : ""
                  } // Highlight the active link
                >
                  <>
                    <Icon name="icon ni ni-user-circle" /> <span> Profile</span>
                  </>
                </Button>

                <Button
                  color="primary"
                  type="button"
                  onClick={() => navigate(routes.contact)}
                  className={
                    location.pathname === routes.contact ? "active" : ""
                  } // Highlight the active link
                >
                  <>
                    <Icon name="icon ni ni-contact" /> <span>Contact</span>
                  </>
                </Button> */}
                {/* <a
                  href={`${process.env.REACT_APP_ACCOUNT_URL}logout`}
                  className="align-center"
                > */}

                <div className="nk-header-tools">
                  <ul className="nk-quick-nav">
                    <li
                      onClick={() => navigate("chat")}
                      className="nk-quick-nav-icon"
                    >
                      <ChatNotification />
                    </li>
                    <li className="notification-dropdown me-n1">
                      <Notification />
                    </li>

                    <li className="user-dropdown">
                      <User />
                    </li>
                  </ul>
                </div>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </>
  );
};
export default AppHeader;
