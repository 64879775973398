import { ToastContainer } from "react-toastify";
const ToastLayout = () => {
  return (
    <>
      <ToastContainer
        hideProgressBar
        closeOnClickmy-
        pauseOnHover
        draggable
        closeButton={
          <div style={{ display: "flex", alignItems: "center", marginRight: "30px" }}>
            <span style={{fontSize : "1.3rem"}}>x</span>
          </div>
        }
      />
    </>
  );
};
export default ToastLayout;
