// src/context/DataProvider.js
import { createContext, useState, useEffect } from "react";
import { getCookie } from "../utils/Utils";

export const DataContext = createContext();

const DataProvider = ({ children }) => {
  const [profileprogress, setProfileProgress] = useState();
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [planEnddates, setPlanEndDates] = useState();
  const [activeTab, setActivetab] = useState("1");
  const [subscribedpalresponce, setSubscribedPalResponce] = useState("");
  const [userProfile, setUserProfile] = useState("");
  const [profileCompletion, setProfileCompletion] = useState();
  const [emailvalidation, setEmailValidation] = useState(false);
  const [geteventdata, setGetEventData] = useState([]);
  const [getTransformSlotdata, setTransformSlotdata] = useState([]);
  const [currency, setCurrency] = useState(null);
  const [auOverview, setAuOverview] = useState("1 month");
  const [auOverview1, setAuOverview1] = useState("7 days");
  const [transactionid, setTransactionId] = useState();
  const [newPlanId, setNewPlanId]= useState();
  const [durationType, setDurationType] = useState("");

  useEffect(() => {
    subscribedPlanAPI();
    // Establish a WebSocket connection
    // const ws = new WebSocket("ws://localhost:8080"); // Replace with your WebSocket server URL

    // // Handle incoming messages
    // ws.onmessage = (event) => {
    //   const messageData = JSON.parse(event.data);

    //   // Display notification when a new enquiry is received
    //   if (messageData.type === "new_enquiry") {
    //     toast.info(messageData.data.message, {
    //       position: toast.POSITION.TOP_RIGHT,
    //       autoClose: 5000,
    //     });
    //   }
    // };

    // // Handle connection closure
    // ws.onclose = () => {
    //   console.log("WebSocket connection closed");
    // };

    // // Clean up the WebSocket connection on component unmount
    // return () => {
    //   ws.close();
    // };
  }, []);

  const subscribedPlanAPI = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/v1/subscription/get-subscribed-plan`,
        {
          method: "POST",
          headers: {
            authToken: getCookie("authToken"),
          },
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        setSubscribedPalResponce(responseData?.status);
        setPlanEndDates(responseData?.data?.package?.end_date);
      } else {
        throw new Error("Failed to fetch plan data");
      }
      return response;
    } catch (error) {
      console.error("Error fetching plan data:", error);
    }
  };

  return (
    <DataContext.Provider
      value={{
        profileprogress,
        setProfileProgress,
        hasUnsavedChanges,
        setHasUnsavedChanges,
        planEnddates,
        setPlanEndDates,
        activeTab,
        setActivetab,
        subscribedpalresponce,
        setSubscribedPalResponce,
        userProfile,
        setUserProfile,
        profileCompletion,
        setProfileCompletion,
        emailvalidation,
        setEmailValidation,
        geteventdata,
        setGetEventData,
        getTransformSlotdata,
        setTransformSlotdata,
        currency,
        setCurrency,
        auOverview,
        setAuOverview,
        auOverview1,
        setAuOverview1,
        transactionid,
        setTransactionId,
        newPlanId,
        setNewPlanId,
        durationType,
        setDurationType
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataProvider;
