import CustomReactSelect from "react-select";
import { useTheme } from "../../layout/provider/Theme";
import { useEffect, useState } from "react";
const ReactSelect = ({ options, id, name, onChange, onBlur, value }) => {
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    if (options && options.length > 0) {
      const selectedOption = options.find((option) => option.value === value);
      setSelected(selectedOption || null);
    }
  }, [value, options]);

  const { skin } = useTheme();
  return (
    <CustomReactSelect
      options={options}
      id={id}
      name={name}
      value={selected}
      onChange={onChange}
      onBlur={onBlur}
      styles={
        skin === "dark" && {
          control: (provided, state) => ({
            ...provided,
            backgroundColor: "transparent",
            color: "white",
            border: "1px solid #384d69",
          }),
          placeholder: (provided, state) => ({
            ...provided,
            color: "#fff",
            opacity: 0.7,
          }),
          singleValue: (provided, state) => ({ ...provided, color: "white" }),
          valueContainer: (provided, state) => ({
            ...provided,
            color: "white",
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: "#141c26",
            color: "white",
            border: "1px solid #384d69",
          }),
        }
      }
    />
  );
};

export default ReactSelect;
